@import url('https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300;400;700&display=swap');
* {
    font-family: 'Sulphur Point', sans-serif;
    font-weight: 400;
    -webkit-appearance: none;
     }

svg {
color: #ffffff;
}

.header {
    position: fixed;
    height: 3.5rem;
    width: 98vw;
    top: 1vh;
    left: 1vw;
    right: 1vw;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color:#ff6600;
    border-radius: 2vh;
}

.header.clearHeader{
    background-color: transparent;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 3.5rem;
    padding-right: 3vw;
    gap: 0.5vw
}
.navButton {
    height: 2.5vh;
    text-align:center;
    border-radius: 1vh;
    border-style: solid;
    border-color: #ffffff;
    font-size: 1.8vh;
    line-height: 0;
    background-color: #ffffff;
    color: #ff6600;
    transition: .3s ease-in;
}

.navButton.invertedColour {
    border-color: #ff6600;
    background-color: #ff6600;
    color: #ffffff;
}

.homePage {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffd1b3' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    display: grid;
    }

.homePageGrid {
    grid-template-rows: 15rem 1fr 10rem;
}

.projectPageGrid {
    grid-template-rows: 10rem 8rem 1fr 10rem;
}

.homePageFlex {
    grid-row: 2;
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

.bigLogo {
    background-color: #ff6600;
    margin-left: 3vw;
    margin-right: 50vw;
    padding-left: 1vw;
    padding-bottom: 1vh;
    border-radius: 2vh;
    display: flex;
    flex-direction: column;
    gap: 5% 0;
}

.bigLogo h1{
    color: #ffffff;
    font-size: 6vh;
    font-weight: 700;
    padding-left: 2vw;
    line-height: 0;
}

.bigInnerLogo {
    background-color: #ffffff;
    color: #ff6600;
    max-width: 80%;
    border-radius: 1vh;
}

.bigInnerLogo h2 {
    flex: 1;
    font-size: 2rem;
    line-height: 0;
    padding-left: 2vw;
    text-align: left;
}

.about, .bootCamp {
    border-style: solid;
    border-color: #ff6600;
    border-radius: 2vh;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.2rem;
    color: #282c34;
    background-color: #ffffff;
}

.about {
    margin-left: 25vw;
    margin-right: 3vw;
}

.bootCamp {
    margin-left: 3vw;
    margin-right: 25vw;
}

.cv, .ioLink {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #ff6600;
    gap: 0.5rem;
}

.cvButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff6600;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    font-size: 1rem;
    color: red;
    border-color: #ffffff;
    border-style: solid;
    line-height: 0;
}

.linkIcon > svg {
    color: #ff6600;
}


.contact h1 {
    color: #ff6600;
    text-align: center;
}

.contactButtons {
    display: flex;
    justify-content: center;
    gap: 6vw;
}

.contactButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff6600;
    width: 7.3rem;
    height: 7.3rem;
    border-radius: 50%;
    font-size: 5rem;
    color: #ffffff;
    border-color: #ffffff;
    border-style: solid;
    line-height: 0;
}

.projectsHeading {
   grid-row: 2;
    color: #ff6600;
    font-size: 6vh;
    line-height: 0;
    padding-left: 4vw;
}

.projects {
    grid-row: 3;
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
    justify-content: center;
}

.projectLink {
    padding: 2vh;
    color: #ff6600;
}

.projectCard{
    width: 450px;
    padding: 2vh;
    border-bottom: 2px solid #d3d3d3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projectCard > h2{
    color: #ff6600;
    text-align: center;
}

img {
    max-width: 400px;
}


@media (max-width: 900px) and (min-width: 701px) {
    .bigLogo {
        margin-right: 41vw;
    }
}

@media (max-width: 700px) and (min-width: 681px) {

    .homePageGrid {
        grid-template-rows: 8rem 1fr 7rem;
    }
    .projectPageGrid {
        grid-template-rows: 6rem 6rem 1fr 7rem;
    }
    .bigLogo {
        margin-right: 38vw;
    }
}

@media (max-width: 680px) and (min-width: 1px) {

    .homePageGrid {
        grid-template-rows: 5rem 1fr 5rem;
    }
    .projectPageGrid {
        grid-template-rows: 4rem 5rem 1fr 5rem;
    }

    .bigLogo {
        margin-right: 3vw;
    }
    .contactButtons {
        gap: 3vw;
    }

    .contactButton {
        width: 5rem;
        height: 5rem;
        font-size: 3.7rem;
    }

    .projectCard {
        width: 98vw;
        padding: 1vw;
    }
    img {
        max-width: 98vw;
    }
}

@media (max-width: 400px) and (min-width: 1px) {

    .bigInnerLogo {
        max-width: 90%;
    }

    .bigInnerLogo h2 {
        font-size: 1.6rem;
    }
}